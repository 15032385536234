body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.displayNone {
  display: none;
}

/* .head1 {
  border: 1px solid #000;
  text-align: center;
  font-weight: bold;
  color: #ff0000;
  font-family: 'Times New Roman';
  font-size: 10pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: "0px 3px 0px 3px";
} */